.container-fluid {
   padding-left: 0;
   padding-right: 0;
   background: rgb(18, 18, 18);
}
.maxme {
   background: rgb(18, 18, 18);
}
.profile-img {
   margin-bottom: 15px;
}
h1 {
   font-size: 3.5rem;
   
}
.socialicons,
.socialicons:hover {
   color: #fff;
   padding-right: 5px;
   transition: all 0.8s;
   margin: 6px;
   font-size: 2rem;
   font-weight: 400;
}
.socialicons:hover,
footer a {
   color: rgba(50, 50, 50, 0.5);
}

#jt-100 {
   height: 100%;
   min-height: 100vh !important;
   min-height: 100%;
   display: flex;
   align-content: center !important;
   align-items: center !important;
}

/* #jt-100 p {
   font-size: 2rem !important;
} */

.jumbotron {
   background: rgb(68, 132, 206);
}

.nounderline {
   text-decoration: none !important;
}
footer {
   padding-bottom: 30px;
}
footer .inner p {
   margin-bottom: 0 !important;
}

.project-max {
   max-width: 700px;
}
.my-project {
   margin-top: 30px;
   margin-bottom: 30px;
}
.media {
   padding-top: 0 !important;
}

@media (min-width: 400px) {
   .my-project {
      margin-top: 60px;
      margin-bottom: 30px;
   }
}

@media (min-width: 760px) {
   .my-project {
      margin-top: 40px;
      margin-bottom: 30px;
   }
}
